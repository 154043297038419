import { useAPIClient } from "../../context/apiClient";
import { useEditorStore } from "@adflow/editor";
import {
  Box,
  Button,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";
import { FC, useCallback, useState } from "react";

export interface StepProps {
  onClose: () => void;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const CreatomateComponentImportModal: FC<Props> = ({ isOpen, onClose }) => {
  const client = useAPIClient();
  const [templateId, setTemplateId] = useState<string>("");
  const [apiKey, setApiKey] = useState<string>("");
  const addElements = useEditorStore(state => state.addElements);
  const setImportSourceType = useEditorStore(
    state => state.setImportSourceType
  );
  const setImportSourceJSON = useEditorStore(
    state => state.setImportSourceJSON
  );
  const handleClose = useCallback(async () => {
    onClose();
  }, [onClose]);

  const [loading, setLoading] = useState(false);
  const handleSubmit = useCallback(async () => {
    setLoading(true);

    try {
      const adTemplateResponse = await client.importCreatomateTemplate({
        templateId: templateId,
        apiKey: apiKey
      });
      addElements(adTemplateResponse.adElements);

      setImportSourceJSON(adTemplateResponse.importSourceJSON ?? "");
      setImportSourceType(adTemplateResponse.importSourceType);
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
    handleClose();
  }, [
    addElements,
    apiKey,
    client,
    handleClose,
    setImportSourceJSON,
    setImportSourceType,
    templateId
  ]);

  // const ErrorAlert = () =>
  //   state.error == null || state.error == "" ? null : (
  //     <Alert status='error'>
  //       <AlertIcon />
  //       {state.error}
  //     </Alert>
  //   );

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size='4xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader data-testid='importFromCreatomateDialogTitle'>
          Import from Creatomate
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormLabel>Creatomate Template ID</FormLabel>
          <Input
            data-testid='CreatomateTemplateIdInput'
            type='text'
            required
            onChange={evt => {
              setTemplateId(evt.target.value);
            }}
            placeholder=''
          />
          <Box pt={2}></Box>
          <FormLabel>API Key</FormLabel>
          <Input
            data-testid='CreatomateApiKeyInput'
            type='text'
            required
            onChange={evt => {
              setApiKey(evt.target.value);
            }}
            placeholder=''
          />
        </ModalBody>
        <ModalFooter>
          <Button
            data-testid='closeButton'
            variant='ghost'
            mr={3}
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            colorScheme='blue'
            onClick={handleSubmit}
            isLoading={loading}
            data-testid='getComponents'
          >
            Import
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

CreatomateComponentImportModal.displayName = "CreatomateComponentImportModal";

export default CreatomateComponentImportModal;
