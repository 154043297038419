import React, {
  FC,
  ReactElement,
  useCallback,
  useContext,
  useState
} from "react";

import { LuDatabase } from "react-icons/lu";

import { ConsoleAPIGraph } from "@adflow/graphqlgen";
import {
  AdElement,
  AdTemplate,
  AdflowAPI,
  EditorSidebarButtonProps,
  EditorState
} from "@adflow/types";
import {
  Box,
  Flex,
  FocusLock,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  useDisclosure
} from "@chakra-ui/react";
import { EditorStoreContext } from "../../";
import { useStore } from "../../hooks/useStore";
import LeftSidebar from "../LeftSidebar";
import Rightsidebar from "../Rightsidebar";
import Toolbox from "../Toolbox";
import Topbar from "../Topbar";
import MultiDataSourcesForm from "./MultiDataSourcesForm";
type Module = {
  name: string;
  EditorSidebarButton: FC<EditorSidebarButtonProps>;
  EditorSidebarView?: FC;
};

interface Props {
  modules: Array<Module>;
  onSave?: (ctx: EditorState) => Promise<void>;
  renderPreview?: (args: RenderPreviewArgs) => ReactElement;
  renderTopbar?: () => ReactElement;
  saveButtonVisible?: boolean;
}

type RenderPreviewArgs = {
  elements: Array<AdElement>;
  importSourceJSON?: string;
  importSourceType?: AdTemplate["importSourceType"];
  brand: ConsoleAPIGraph.Brand | null;
  fonts: Array<{
    fontFamily: string;
    weight: number;
    style: string;
    src: string;
  }>;
  displayOptions?: AdflowAPI.AdTemplateDisplayOptions;
  selectedElementId: string | null;
  isIndexPreview: boolean;
};

const Editor: FC<Props> = ({
  modules,
  renderPreview,
  renderTopbar,
  onSave,
  saveButtonVisible = true
}) => {
  const ctx = useContext(EditorStoreContext);
  const [activeModule, setActiveModule] = useState<string>("");
  const {
    elements,
    brand,
    fonts,
    displayOptions,
    selectedElementId,
    importSourceJSON,
    importSourceType
  } = useStore(state => ({
    brand: state.brand,
    elements: state.elements,
    fonts: state.customFonts,
    displayOptions: state.displayOptions,
    selectedElementId: state.selectedElementId,
    importSourceJSON: state.importSourceJSON,
    importSourceType: state.importSourceType
  }));
  const isIndexPreview = false;
  // const ActiveEditorSidebarView = useMemo(() => {
  //   return modules.find(module => module.name === activeModule)
  //     ?.EditorSidebarView;
  // }, [activeModule, modules]);

  // Reference to the store is stable between renders. As we need
  // access to the full editor state it's best to use this here to
  // avoid unnecessary re-renders. useStore() is good if you want
  // to subscribe to a _part_ of the store state.
  const handleOnSave = useCallback(async () => {
    if (ctx.store != null && onSave != null) {
      await onSave(ctx.store.getState());
    }
  }, [ctx.store, onSave]);

  // const selectedElement = useEditorStore(s => s.selectedElementId);
  const s = useStore(state => ({
    setSelectedElement: state.setElementSelected
  }));

  const handleClick = useCallback(() => {
    s.setSelectedElement(null);
  }, [s]);

  return (
    <Flex flex={1} width='100%' height='100%' direction='column'>
      {renderTopbar ? (
        renderTopbar()
      ) : (
        <Topbar onSave={handleOnSave} saveButtonVisible={saveButtonVisible} />
      )}
      {/* fill full height - height of toolbar */}
      <Flex flex={1} height='calc(100% - 66px)'>
        <Toolbox>
          {modules.map(M => (
            <M.EditorSidebarButton
              key={M.name}
              openSidebarView={value => {
                setActiveModule(value);
              }}
              selected={activeModule === M.name}
            ></M.EditorSidebarButton>
          ))}
          <DataSourceBtn />
        </Toolbox>
        <LeftSidebar />
        <Box flexGrow={1} onClick={handleClick} />
        <Box m={"auto"}>
          {renderPreview &&
            renderPreview({
              elements,
              brand,
              fonts,
              displayOptions,
              selectedElementId,
              isIndexPreview,
              importSourceJSON,
              importSourceType
            })}
        </Box>
        <Box flexGrow={1} onClick={handleClick} />
        <Rightsidebar />
      </Flex>
    </Flex>
  );
};

Editor.displayName = "Editor";

export default Editor;
export type { Props as EditorProps };
export type { RenderPreviewArgs };
const DataSourceBtn: FC<Record<string, unknown>> = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const firstFieldRef = React.useRef(null);

  return (
    <Popover
      isLazy
      isOpen={isOpen}
      initialFocusRef={firstFieldRef}
      onOpen={onOpen}
      onClose={onClose}
      placement='right'
      closeOnBlur={true}
    >
      <PopoverTrigger>
        <IconButton
          size='lg'
          px={4}
          aria-label='Search database'
          icon={<Icon as={LuDatabase} />}
          borderRadius='100%'
          aspectRatio='1/1'
          data-testid='dataSourcesButton'
        />
      </PopoverTrigger>
      <PopoverContent w={500} p={5}>
        <FocusLock restoreFocus persistentFocus={false}>
          <PopoverArrow />
          <PopoverCloseButton />
          <MultiDataSourcesForm
            firstFieldRef={firstFieldRef}
            onCancel={onClose}
          ></MultiDataSourcesForm>
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
};
