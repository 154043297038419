import { FC, PropsWithChildren } from "react";

import { AdTemplate } from "@adflow/types";
import { createGenericContext } from "@adflow/utils";

interface APIClientContext {
  importCreatomateTemplate: (params: {
    templateId: string;
    apiKey: string;
  }) => Promise<AdTemplate>;
}

const [useAPIClient, APIClientContextProvider] =
  createGenericContext<APIClientContext | null>();

const Provider: FC<PropsWithChildren<APIClientContext>> = ({
  children,
  ...value
}) => {
  return (
    <APIClientContextProvider value={value}>
      {children}
    </APIClientContextProvider>
  );
};

export { Provider, useAPIClient };
export type { APIClientContext as APIClient };
