import { FC, useEffect, useMemo, useRef } from "react";

import { Preview } from "@creatomate/preview";
import { AdElement, isTextElement } from "@adflow/types";

export const CreatomatePreview: FC<{
  source: string;
  elements: Array<AdElement>;
}> = ({ source, elements }) => {
  const previewRef = useRef(null);
  const previewInstance = useRef<Preview | null>(null);

  const parsedSource = useMemo(() => {
    if (source) {
      return JSON.parse(source);
    }
    return {};
  }, [source]);

  const modifications = useMemo(() => {
    return mapModifications(elements.filter(isTextElement));
  }, [elements]);

  useEffect(() => {
    if (previewRef.current && !previewInstance.current) {
      const preview = new Preview(
        previewRef.current,
        "player",
        "public-b0xkd0kd3fyeen70pflgz5th" //public token - NOT THE API KEY
      );

      preview.onReady = async () => {
        previewInstance.current = preview;
        await preview.setSource(parsedSource);
        await preview.setModifications(modifications);
      };
    }
  }, [parsedSource, modifications]);

  useEffect(() => {
    if (previewInstance.current) {
      previewInstance.current.setModifications(modifications);
    }
  }, [previewInstance, modifications]);

  return (
    <div
      ref={previewRef}
      style={{
        position: "relative",
        width: "600px",
        height: "600px",
        transformOrigin: "left top"
      }}
    ></div>
  );
};

function mapModifications(
  elements: Array<AdElement & { type: "TEXT" }>
): Record<string, string> {
  const modifications: Record<string, string> = {};
  elements.forEach(e => {
    modifications[e.id + ".text"] = e.data.content;
  });
  return modifications;
}

export default CreatomatePreview;
