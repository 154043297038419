import { useMemo } from "react";

import * as Adflow from "adflow";
import { useParams } from "react-router-dom";

import {
  APIClientContext as FigmaAPIClientContext,
  name as FigmaName,
  EditorSidebarButton as FigmaSidebarButton
} from "@adflow/figma";

import {
  APIClientContext as ShadowBrandAPIClientContext,
  name as ShadowBrandName,
  EditorSidebarButton as ShadowBrandSidebarButton,
  EditorSidebarView as ShadowBrandSidebarView
} from "@adflow/shadow-brand";
import {
  APIClientContext as SelectAdvertiserAPIClientContext,
  name as MarketingCloudName,
  EditorSidebarButton as MarketingCloudSidebarButton,
  EditorSidebarView as MarketingCloudSidebarView
} from "@adflow/marketing-cloud";
import { Datasources, EditorState } from "@adflow/types";

import { TRPCClient } from "../utils/apiClient";
import { useAdTemplateData } from "../utils/hooks/useAdTemplateData";

function Edit() {
  const { id: idParam } = useParams<{ id: string | undefined }>();
  const id = idParam || "";

  const {
    adTemplate,
    brand,
    fonts,
    ready,
    warningOpen,
    warningMessage,
    setWarningOpen,
    hasEditPermissions
  } = useAdTemplateData(id);

  const modules = useMemo(
    () => [
      {
        name: FigmaName,
        EditorSidebarButton: FigmaSidebarButton
      },
      {
        name: ShadowBrandName,
        EditorSidebarButton: ShadowBrandSidebarButton,
        EditorSidebarView: ShadowBrandSidebarView
      },
      {
        name: MarketingCloudName,
        EditorSidebarButton: MarketingCloudSidebarButton,
        EditorSidebarView: MarketingCloudSidebarView
      }
    ],
    []
  );

  const defaultStoreValues = useMemo<Partial<EditorState>>(() => {
    if (!adTemplate) return {};

    let teamAssetsConfig = adTemplate.displayOptions?.teamAssets;
    if (!teamAssetsConfig) {
      teamAssetsConfig = {
        type: "JERSEY",
        fallback: "NONE"
      };
    }

    return {
      ...adTemplate,
      elements: adTemplate.adElements,
      brand,
      customFonts: fonts,
      teamAssets: teamAssetsConfig
    };
  }, [adTemplate, brand, fonts]);

  const dataSources = useMemo(() => {
    return Datasources.createDataSourceDefinitions(
      adTemplate?.dataSources || null
    );
  }, [adTemplate]);

  const renderPreview = (args: Adflow.RenderPreviewArgs) => {
    return args.importSourceType === "creatomate" && args.importSourceJSON ? (
      <Adflow.CreatomatePreview
        source={args.importSourceJSON}
        elements={args.elements}
      />
    ) : (
      <Adflow.Preview
        elements={args.elements}
        brand={args.brand}
        fonts={args.fonts}
        displayOptions={args.displayOptions}
        selectedElementId={args.selectedElementId}
        isIndexPreview={false}
      />
    );
  };

  const onSave = async (editorState: EditorState) => {
    await TRPCClient.client.adTemplate.upsert.mutate({
      id,
      name: editorState.name,
      brandId: editorState.brand?._id,
      dataSources: editorState.dataSources,
      adElements: editorState.elements,
      displayOptions: editorState.displayOptions,
      businessEntityId: editorState.businessEntityId,
      importSourceType: editorState.importSourceType
    });
  };

  if (!ready) {
    return <span>Loading</span>;
  }
  if (warningOpen) {
    return (
      <Adflow.WarningModal
        isOpen={warningOpen}
        onClose={() => setWarningOpen(false)}
        warning={warningMessage}
        title='Warning'
      ></Adflow.WarningModal>
    );
  }

  return (
    <div
      className='container'
      style={{ width: "100%", height: "100%", overflow: "hidden" }}
    >
      <Adflow.EditorProvider
        dataSources={dataSources}
        defaultStoreValues={defaultStoreValues}
      >
        <FigmaAPIClientContext.Provider {...TRPCClient.figmaClient}>
          <ShadowBrandAPIClientContext.Provider {...TRPCClient.consoleClient}>
            <SelectAdvertiserAPIClientContext.Provider
              {...TRPCClient.marketingCloudClient}
            >
              <Adflow.Editor
                saveButtonVisible={hasEditPermissions}
                onSave={onSave}
                modules={modules}
                renderPreview={renderPreview}
              ></Adflow.Editor>
            </SelectAdvertiserAPIClientContext.Provider>
          </ShadowBrandAPIClientContext.Provider>
        </FigmaAPIClientContext.Provider>
      </Adflow.EditorProvider>
    </div>
  );
}
export default Edit;
